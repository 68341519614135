.brand-text {
	font-size: $font-size-sm;
	word-wrap: break-word;
}


@include media-breakpoint-up(md) {
	.brand-text {
		font-size: $font-size-base;
	}
}