.btn-floating {
	width: 2.3125rem;
	height: 2.3125rem;
	display: grid;	
	
	> i.bi {
		display: grid;

		&::before {
			// align-self: center;
			place-self: center;
		}
	}
}

.btn-floating,
[class*=btn-outline-].btn-floating {
	border-radius: 50%;
	padding: 0;
	position: relative;
}