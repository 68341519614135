.intro{
	position:relative;

	&::before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 90vh;
		background: rgba($gray-900, .8) url("../assets/mount-sm.jpg") center no-repeat;
		background-size: cover;
		filter: grayscale(30%);
		background-blend-mode: darken;
	}
}


.section-divider {
	height: 3rem;
	background-color: rgba($black, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
}


.footer{
	background-image: url("../assets/bg-koru.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}


@include media-breakpoint-up(md) {
	.intro::before {
		background: rgba($gray-900, .8) url("../assets/mount.jpg") center no-repeat;
		background-size: cover;
	}

	.footer {
		background-position: right;
		background-size: contain;
		background-repeat: no-repeat;
	}
}