html {
	scroll-padding-top: 7rem;
}

body {
	position: relative;
	overflow-y: auto;
}
.min-vh-80 {
	min-height: 80vh;
}
.min-vh-90 {
	min-height: 90vh;
}

.vh-80{
	height: 80vh;
}

.vh-90{
	height: 90vh;
}

textarea#msg {
	transition: height .5s;
}