.activated {
	color: $white;
	background-color: $primary;
	text-decoration: none;
}

button.navbar-toggler .navbar-toggler-icon {
	background-image: unset;
	background-color: $light;
	transform: rotate(90deg);
	transition: all 0.5s ease;
	position: relative;
	width: 0.1em;
	height: 1.1em;
	&::before {
		background-color: inherit;
		width: 0.1em;
		height: inherit;
		transition: inherit;
		content: "";
		position: absolute;
		transform: translateX(-0.4em);
		
	}
	&::after {
		background-color: inherit;
		transition: inherit;
		width: 0.1em;
		height: inherit;
		content: "";
		position: absolute;
		transform: translateX(0.3em);
		
	}
}

button.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
	background-color: unset;
	&::after {
		transform: translateY(0) translateX(-.05em) rotate(-45deg);
		background-color: $light;
		
	}
	&::before {
		transform: translateY(0em) translateX(-.05em) rotate(45deg);
		background-color: $light;
	}
}


.lang_label{
	position: absolute;
}

@keyframes move-in {
	0%, 33% {
		transform: translateY(20px);
        opacity: 0;
	}
	100% {
		transform: translateY(0);
        opacity: 1;
		visibility:visible;
	}
}

@keyframes move-out {
	0% {
		transform: translateY(0);
        opacity: 1;
        visibility: visible;
	}
	100% {
		transform: translateY(-20px);
        opacity: 0;
		visibility: hidden;
	}
}