.card {
	// max-width: 300px;
	.social-link {
		width: 30px;
		height: 30px;
		border: 1px solid $secondary;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $secondary;
		border-radius: 50%;
		transition: all 0.3s;
		font-size: 0.9rem;

		&:hover {
			color: currentColor;
			background-color: $secondary; //tint-color($secondary, 40%)
			// border-color: currentColor;
		}
	}
}