.moving-mouse-holder {
	margin: auto;
	width: 170px;

	.mouse {
		width: 26px;
		height: 40px;
		position: relative;
		right: 0;
		border-radius: 18px;
		border: 2px solid $light;
	}

	.mouse-button {
		background-color: $light;
		width: 4px;
		height: 10px;
		border-radius: 2px;
		position: absolute;
		top: 10px;
		left: 50%;
		margin: 0 0 0 -2px;
		animation: mouse-scroll 1s infinite alternate;
	}

}

@keyframes mouse-scroll {
	to {
		transform: translate(0, 6px) scale(1, 0.8);
	}
}
