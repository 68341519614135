// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

/*! `Lovey` Bootstrap 4 theme */
@import url(https://fonts.googleapis.com/css?family=Muli:200,300,400,700);
// $font-family-base:Muli;
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@700;900&display=swap');
// $headings-font-family:Lato;
$headings-font-family:"Source Serif Pro", serif;


// $primary:#1B74E4; //#D80E70;
$secondary:#adb5bd;
// $success:#4EF037;
// $danger:#FA4659;
// $info:#00BBF0;
// $warning:#f4f133;
 $light:#fbfcfe; //from dark mode MUIU
 $dark: #313235; //from dark mode MUIU

//  background: #313235; //$gray-900;
//  $facebook: #1877F2;


// Add SASS theme customizations here..


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "../node_modules/bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
// $enable-gradients: true;
$enable-shadows: true;

// $offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
// $body-color: #333;
// $body-bg: #313235;
// $border-radius: .4rem;





// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Custom overrides
@import "variables";



// $theme-colors: (
//     primary: $primary,
//     secondary: $secondary,
//     success: $success,
//     info: $info,
//     warning: $warning,
//     danger: $danger,
//     light: $light,
//     dark: $dark,
//     // add any additional color below
//     // danger2: $danger2,
//     // twitter: $twitter
//   );

$custom_colors:(
  darker: $gray-900);

$theme-colors: map-merge($theme-colors, $custom_colors);

// Optional
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";



@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar"; // Requires nav
@import "../node_modules/bootstrap/scss/card";
// @import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/accordion";
// @import "../node_modules/bootstrap/scss/pagination";
// @import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
// @import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
// @import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal"; // Requires transitions
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
// @import "../node_modules/bootstrap/scss/offcanvas"; // Requires transitions


// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";


//
// Custom styles
//

// @import "icon-list";


// Custom overrides

@import "base";
@import "header";
@import "sections";
@import "accordion";
@import "buttons";
@import "card";
@import "mouse";
@import "cookies";
@import "menus";

// @import '../node_modules/bootstrap-icons/font/bootstrap-icons';




// body {
//   padding: 3rem 1.5rem;
// }

// Style Bootstrap icons
// .bi {
//   fill: currentColor;
// }



