
// Body
//
// Settings for the `<body>` element.


$body-color: #fbfcfe;

// Accordion Header
$accordion-dark: $gray-500;
// $accordion-bg: $gray-700;
$accordion-body-color: $dark;
$accordion-color: $gray-100;

$accordion-button-bg: $gray-800; //$gray-800
$accordion-button-active-bg: $gray-800;

$accordion-button-active-color: $accordion-color;

$accordion-button-color: $body-color;
$accordion-border-color: $gray-900;

$accordion-button-focus-border-color:$gray-900;
$accordion-button-focus-box-shadow: none;

$accordion-icon-active-color:$accordion-color;
$accordion-icon-color:$accordion-color;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") ;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") ;

// Modal

$modal-backdrop-opacity:            .8;