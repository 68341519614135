.accordion {
	&-header {
		font-family: $font-family-base;
	}
	&-body{
		color:$dark;
	}
}

#accordionRules {

	li {
		line-height: 2rem;
	}
}